import React from "react"
import Layout from "@/components/layout"
import { Work } from "../../components/work"
import { Link } from "gatsby"

export default function Header() {
  return (
    <Layout currentPage="index">
      <Work work="animation-reel" />
    </Layout>
  )
}
